module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Panel zarządzania","htmlFavicon":"src/assets/icons/iuvenes.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Iuvenes Engineering","short_name":"Iuvenes","lang":"pl","start_url":"/","background_color":"#4A615D","theme_color":"#4A615D","display":"minimal-ui","icon":"src/assets/icons/iuvenes.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"12ed73766d2940807a3c2e4f4ec42f0c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
